import React from 'react';
import styled, { css } from 'styled-components';

import { getTextColorBasedOnBg } from 'libs/content';
import { TitleWithBadgeStyling } from 'libs/SharedStyling';
import { AnimationWrap } from 'libs/animations';
import Heading from 'libs/heading';
import AltiboxLogo from 'images/fiber/AltiboxLogo';
import Badge from 'components/products/Badge';
import TagsAndDate from 'components/blog/TagsAndDate';

const MainTitle = styled(Heading)`
	margin: ${p => `0 0 ${p.theme.spacing.desktop.xxsmall}`};
	color: ${p => p.color && p.theme.colors[p.color]};

	// When no text below the title, medium margin-bottom
	${p =>
		!p.hasText &&
		!p.marginBottom &&
		css`
			margin: ${p => `0 0 ${p.theme.spacing.desktop.medium}`};
			${p =>
				p.theme.media.mediumOnly(css`
					margin: ${p => `0 0 ${p.theme.spacing.tablet.medium}`};
				`)}
				${p =>
					p.theme.media.smallOnly(css`
						margin: ${p => `0 0 ${p.theme.spacing.mobile.medium}`};
					`)}
			}
		`}

	${p =>
		!!p.marginBottom &&
		css`
			margin-bottom: ${p =>
				`${p.theme.spacing.desktop[p.marginBottom]}`} !important;
			${p =>
				p.theme.media.mediumOnly(css`
					margin: ${p =>
						`0 0 ${p.theme.spacing.tablet[p.marginBottom]}`};
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					margin: ${p =>
						`0 0 ${p.theme.spacing.mobile[p.marginBottom]}`};
				`)}
		`}

	// When title is h1, set small margin-bottom
	${p =>
		p.level === 'h1' &&
		css`
			margin-bottom: ${p => p.theme.spacing.desktop.xsmall} !important;
		`}
`;

const TopWrap = styled.div`
	position: relative;
	margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
	display: inline-block;
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall} !important;
		`)}

	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall} !important;
		`)}

	// When main-title above TopWrap is h1, set small margin-bottom
	${p =>
		p.level === 'h1' &&
		css`
			margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
		`}

	span {
		font-size: 17px;
		line-height: 29px;
		font-weight: 500;
		color: ${p => p.color && p.theme.colors[p.color]};
		text-transform: uppercase;
	}
	.badge__news {
		${p =>
			p.theme.media.large(css`
				right: -50px !important;
				top: calc(-100% - 35px) !important;
			`)}
	}
`;

const TitleWrapper = styled.div`
	position: relative;
	text-align: ${p => (p.$centered === 'true' ? 'center' : 'left')};

	${p =>
		p.$hasBadge === 'true' &&
		css`
			${TitleWithBadgeStyling}
		`}

	${p =>
		p.$hasBadge === 'true' &&
		p.$centered === 'true' &&
		css`
			margin: ${p => `0 auto ${p.theme.spacing.desktop.xsmall}`};
		`}
`;

/**
 * Represents a component with a stick-title and a main title
 * @param {string} stickTitle - The stick-title of the component.
 * @param {string} title - The main title of the component.
 * @param {boolean} transition - Wheter the component should have animation or not
 * @param {boolean} transitionEach - Wheter the component should have animation on each text or not
 * @param {string} headinglevel - The heading level for the title of the component
 * @param {boolean} isfirstheading - Whether the heading is the first one on the page
 * @param {...object} props - The rest of the properties for the component
 */
export default function Title({
	stickTitle = '',
	title = '',
	componentSettings = {},
	transitions = false,
	transitionEach = true,
	headinglevel = 'h2',
	isfirstheading = false,
	...props
}) {
	// Get correct text-colors based on bg-color
	const textcolors = getTextColorBasedOnBg(
		props?.bg || componentSettings?.bg,
		componentSettings?.shadow
	);

	return (
		<AnimationWrap
			className="title"
			doanimate={transitions && transitionEach}
			direction="down"
			delay={1}>
			<TitleWrapper
				$hasBadge={componentSettings?.badgenew}
				$centered={componentSettings?.centered}>
				<StickTitle
					title={stickTitle}
					textcolors={textcolors}
					level={(isfirstheading && 'h1') || headinglevel || 'h2'}
					{...props}
					{...componentSettings}
				/>

				<MainTitle
					level={(isfirstheading && 'h1') || headinglevel || 'h2'}
					color={textcolors?.text}
					className={props?.headinglevelclass || ''}
					{...props}>
					{title}
				</MainTitle>
			</TitleWrapper>
		</AnimationWrap>
	);
}

/**
 * Represents the stick-title of the component
 * @param {string} title - The title of the component.
 * @param {string} showaltiboxlogo - Wheter to show the Altibox logo or not
 * @param {array} group - The groups to show articles from
 * @param {string} date - The date of the article
 * @param {boolean} showTags - Determines whether tags should be shown.
 * @param {object} textcolors - The text-colors for the component
 */
function StickTitle({ ...props }) {
	// Whether has stick-title, altibox-logo or tags/date
	const hasTitle =
		!!props?.title ||
		props?.showaltiboxlogo === 'true' ||
		!!(props?.showTags && !!(props?.group?.length > 0 || props?.date));

	// News-badge only if no title
	if (!hasTitle && props?.badgenew === 'true') {
		return <Badge variant="news" {...props} />;
	}

	// Altibox Logo
	if (props?.showaltiboxlogo === 'true') {
		return (
			<TopWrap color={props?.textcolors?.stickTitle} level={props?.level}>
				<AltiboxLogo
					color={
						(props?.textcolors?.text === 'white' && 'white') ||
						'black'
					}
				/>
			</TopWrap>
		);
	}

	// Stick-title and optional news-badge
	if (props?.title) {
		return (
			<TopWrap color={props?.textcolors?.stickTitle} level={props?.level}>
				<div>
					{props?.badgenew === 'true' && (
						<Badge variant="news" {...props} />
					)}
					<span>{props?.title}</span>
				</div>
			</TopWrap>
		);
	}

	// Tags (groups) and post date
	if (props?.showTags && (props?.group?.length > 0 || props?.date)) {
		return (
			<TagsAndDate
				tags={props?.group
					?.filter(g => g?.visibility)
					?.map(g => g?.title)}
				align="left"
				date={props?.date}
				style={{
					marginTop: '0',
					marginBottom: '20px',
				}}
			/>
		);
	}
}

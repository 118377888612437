import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings } from 'libs/content';
import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';
import Title from './Title';
import Text from './Text';
import MediumWidth from 'layouts/medium-width';

const Wrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};

	figure {
		text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
		.lazy-image img {
			margin: ${p => (p.$centered === 'true' && '0 auto') || '0'};
		}
	}

	// When the component index is not 0 and has not custom spacing-bottom, change to medium padding-bottom
	${p =>
		p.$componentindex !== 0 &&
		!p?.$spacing?.bottom &&
		css`
			> .spacing {
				padding-bottom: ${p => p.theme.spacing.desktop.medium};
				${p =>
					p.theme.media.mediumDown(css`
						padding-bottom: ${p => p.theme.spacing.tablet.medium};
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						padding-bottom: ${p => p.theme.spacing.mobile.medium};
					`)}
			}
		`}

	// When component already has a parent-spacing, remove the padding
	${p =>
		p.$nested &&
		css`
			> .spacing {
				padding-top: 0 !important;
				margin-top: 0 !important;
				${p =>
					p?.$spacing?.bottom !== 'medium' &&
					css`
						padding-bottom: 0 !important;
					`}
			}
			.max-width,
			.medium-width {
				padding: 0;
			}
		`} 

	// If the component is centered and is the first component on the page, change the max-width
	${p =>
		p.$centered === 'true' &&
		p.$componentindex === 0 &&
		css`
			.max-width {
				max-width: ${p => p.theme.widths.medium};
			}
		`}
`;

const Inner = styled.div`
	margin: ${p => p?.margin || '0 auto'};
	&.medium-width {
		max-width: ${p => p.theme.widths.medium};
	}
`;

/**
 * Represents an introductional component with a title and an optional short text below or children.
 * Can be used to introduce a section or a page.
 * @param {string} stickTitle - The small stick-title of the component, placed above the title.
 * @param {string} title - The title of the component.
 * @param {object} text - The text for the component.
 * @param {node} children - The children for the component.
 * @param {array} settings - The settings for the component.
 * @param {string} headinglevel - The heading level for the title of the component
 * @param {boolean} isfirstheading - Whether the heading is the first one on the page
 * @param {boolean} nested - Whether the component is nested in another component that has padding
 * @param {boolean} noWrappers - Whether to remove the wrappers around the component
 * @param {...object} props - The rest of the properties for the component
 */
export default function TitleAndText({
	stickTitle = '',
	title = '',
	text = '' || {},
	children = null,
	settings = [],
	headinglevel = 'h2',
	isfirstheading = false,
	nested = false,
	noWrappers = false,
	...props
}) {
	const componentSettings = getComponentSettings({
		settings,
		props,
		content: text,
	});

	// Shorter variable for the centered prop
	componentSettings.centered =
		props?.centered === 'true' || componentSettings?.centered === 'true'
			? 'true'
			: 'false';

	// Shorter variable for the wrapperWidth prop
	const Width = nested
		? MaxWidth
		: props?.wrapperWidth === 'medium'
		? MediumWidth
		: MaxWidth;

	// Check if has either children or text
	const hasText =
		children ||
		text?.raw ||
		text?.childMarkdownRemark ||
		typeof text === 'string' ||
		props?.buttons?.length > 0 ||
		componentSettings?.showappdownloadbtns === 'true';

	// Return nothing if is has no text and no title
	if (!hasText && !title) return null;

	// Common Title and Text components rendering logic
	const renderContent = () => (
		<>
			{componentSettings?.hidetitle !== 'true' && (
				<Title
					stickTitle={stickTitle}
					title={title}
					componentSettings={componentSettings}
					headinglevel={headinglevel}
					isfirstheading={isfirstheading}
					hasText={hasText}
					{...props}
				/>
			)}
			{hasText && (
				<Text
					text={text}
					children={children}
					componentSettings={componentSettings}
					isfirstheading={isfirstheading}
					wrapperWidth={props?.wrapperWidth}
					nested={nested}
					{...props}
				/>
			)}
		</>
	);

	// If noWrappers is true, return the content without wrappers
	if (noWrappers) {
		return (
			<div
				className="component__title-and-text"
				data-cy="component__title-and-text">
				{renderContent()}
			</div>
		);
	}

	return (
		<Wrapper
			className="component__title-and-text"
			data-cy="component__title-and-text"
			$centered={componentSettings?.centered}
			$componentindex={props?.componentindex}
			$nested={nested}
			$spacing={props?.spacing}>
			<Spacing {...props}>
				<Width
					className={`${
						props?.wrapperWidth === 'large' ? 'max' : 'medium'
					}-width`}>
					{((title && componentSettings?.hidetitle !== 'true') ||
						hasText) && (
						<Inner
							margin={
								componentSettings?.centered === 'true'
									? '0 auto'
									: props?.margin || '0'
							}
							className={
								((componentSettings?.centered === 'true' ||
									nested) &&
									'max-width') ||
								(props?.wrapperWidth === 'large' &&
									'max-width') ||
								(props?.wrapperWidth === 'medium' &&
									'medium-width') ||
								'medium-width'
							}>
							{renderContent()}
						</Inner>
					)}
				</Width>
			</Spacing>
		</Wrapper>
	);
}
